import './App.css';
import Index from './pages/Index';

function App() {
  return (
    <>
    <Index/>
    </>
  );
}

export default App;
